<template>
  <div class="dij-table-measurments">
    <b-collapse
      class="panel"
      animation="slide"
      @open="() => (isOpen = true)"
      @close="() => (isOpen = false)"
      aria-id="contentIdForTable"
    >
      <template #trigger>
        <div
          class="panel-heading table-title"
          role="button"
          aria-controls="contentIdForTable"
          :aria-expanded="false"
        >
          <strong
            >Table
            <b-icon icon="menu-down" v-if="isOpen" />
            <b-icon icon="menu-up" v-else />
          </strong>
        </div>
      </template>

      <div style="background-color: white; height: 300px">
        <div class="table-content">
          <table v-if="tableBubblesItems.length > 0">
            <thead>
              <tr>
                <th class="table-cell">
                  <b-button
                    v-if="!isInspectorMode()"
                    type="is-success is-light"
                    size="is-small"
                    @click="addRow"
                    >Add row</b-button
                  >
                </th>
                <th
                  v-for="(header, index) in tableData[0]"
                  :key="`head-${index}`"
                  class="table-cell"
                  style="text-align: center"
                  :contenteditable="false"
                >
                  {{ header }}
                  <div
                    v-if="
                      inspections[index] && inspections[index].hasDescription
                    "
                    class="table-header-description"
                  >
                    {{ inspections[index].description }}
                  </div>
                </th>
              </tr>
            </thead>

            <tr
              v-for="(row, rowIndex) in tableData.slice(1)"
              :key="`row-${rowIndex}`"
              :class="{
                'odd-row': rowIndex % 2 !== 0,
                'even-row': rowIndex % 2 === 0,
              }"
            >
              <td
                class="table-cell"
                :contenteditable="!isInspectorMode()"
                @blur="onFirstCellLeave(rowIndex, $event)"
              >
                {{ measurementsColumnNames[rowIndex] }}
              </td>

              <td
                v-for="(cell, colIndex) in row"
                :key="`cell-${rowIndex}-${colIndex}`"
                class="table-cell"
                :data-cell="`${rowIndex}-${colIndex}`"
                :class="{
                  'red-text': !areMeasurementsOk(colIndex, rowIndex),
                }"
                :contenteditable="isInspectorMode()"
                v-text="tableData[rowIndex + 1][colIndex]"
                @blur="onCellLeave(rowIndex, colIndex, $event)"
                @focus="onCellFocus(rowIndex, colIndex)"
              ></td>
            </tr>

            <tr>
              <td
                class="table-cell"
                :contenteditable="false"
                :colspan="tableBubblesItems.length + 1"
              >
                <b-button type="is-danger" size="is-small" @click="removeRow"
                  >Remove Row</b-button
                >
              </td>
            </tr>
          </table>
        </div>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import debounce from 'lodash/debounce';
import TableQuestionLayout from '../Shared/InstructionView/components/TableQuestionLayout';

const EMPTY_MEASUREMENT_VALUE = {
  value: '',
  images: [],
  comment: '',
};

export default {
  components: { TableQuestionLayout },
  props: ['tableBubblesItems', 'tool'],
  data() {
    return {
      isOpen: true,
      inspections: [],
    };
  },

  watch: {
    tableBubblesItems() {
      this.inspections = this.tableBubblesItems;
    },
  },

  mounted() {
    this.inspections = this.tableBubblesItems;
  },

  computed: {
    tableData() {
      const data = [];
      const header = [];
      this.inspections.sort((a, b) => a.number - b.number);
      this.inspections.forEach((item) => {
        header.push(`Label: ${item.number} (${item.type})`);
      });

      data.push(header);
      const measurementsLength = this.inspections.reduce((max, item) => {
        return Math.max(max, item.measurements.length);
      }, 0);
      for (let i = 0; i < measurementsLength; i++) {
        const col = [];
        this.inspections.forEach((item) => {
          if (
            item.type === 'visual' &&
            item.measurements[i].value === 'notCompleted'
          ) {
            col.push('');
          } else {
            col.push(item.measurements[i].value);
          }
        });
        data.push(col);
      }
      return data;
    },

    measurementsColumnNames() {
      const columnNames = [];

      this.inspections[0].measurements.forEach((measurement, index) => {
        if (measurement.tableName) {
          columnNames.push(measurement.tableName);
        } else {
          columnNames.push(index + 1);
        }
      });

      return columnNames;
    },
  },
  methods: {
    onCellFocus(rowIndex, colIndex) {
      if (!this.isInspectorMode()) return;
      this.inspections[colIndex].selectedMeasurementNumber = rowIndex;
      this.$parent.selectTableInspection(this.inspections[colIndex]);
    },

    onCellLeave: debounce(function (rowIndex, colIndex, event) {
      // Update the data
      this.inspections[colIndex].measurements[rowIndex].value =
        event.target.innerText;

      // Notify parent about the update
      this.$parent.updateInTableMeasurment(this.inspections[colIndex]);
    }, 100),

    onFirstCellLeave: debounce(function (rowIndex, event) {
      this.inspections.forEach((item) => {
        item.measurements[rowIndex].tableName = event.target.innerText;
      });

      // Notify parent about the update
      this.$parent.updateInTableMeasurments(this.inspections);
    }, 100),

    addRow() {
      // Use Vue.set or replace the array to ensure reactivity
      this.inspections = this.inspections.map((item) => {
        // Increment measurement count
        item.measurementCount++;
        // Ensure the measurements array is updated reactively
        const updatedMeasurements = [...item.measurements];
        for (let index = 0; index < item.measurementCount; index++) {
          if (!updatedMeasurements[index]) {
            updatedMeasurements[index] = { ...EMPTY_MEASUREMENT_VALUE };
          }
        }

        return {
          ...item,
          measurements: updatedMeasurements, // Replace with a new array
        };
      });
      this.$parent.updateInTableMeasurments(this.inspections);
    },

    removeRow() {
      this.inspections = this.inspections.map((item) => {
        if (item.measurementCount <= 1) return item;
        item.measurementCount--;

        const updatedMeasurements = [...item.measurements];
        updatedMeasurements.pop();

        return {
          ...item,
          measurements: updatedMeasurements, // Replace with a new array
        };
      });
      this.$parent.updateInTableMeasurments(this.inspections);
    },

    focusCell(inspection) {
      if (this.isInspectorMode()) {
        const colIndex = this.inspections.findIndex(
          (item) => item._id === inspection._id
        );

        let { selectedMeasurementNumber } = inspection;
        if (!selectedMeasurementNumber) selectedMeasurementNumber = 0;

        const selector = `[data-cell="${selectedMeasurementNumber}-${colIndex}"]`;

        this.$nextTick(() => {
          const cell = document.querySelector(selector);
          if (cell) {
            cell.focus();
          } else {
            console.warn(`Cell with selector ${selector} not found`);
          }
        });
      }
    },

    areMeasurementsOk(inspectionIndex, measurementIndex) {
      const inspection = this.inspections[inspectionIndex];
      let ok = true;
      if (inspection.type === 'generic') {
        ok =
          (inspection.images && inspection.images.length > 0) ||
          (inspection.comment && inspection.comment.length) > 0 ||
          (inspection.measurements[0] &&
            inspection.measurements[0].value !== '');
      } else if (inspection.type === 'visual') {
        const notFilled = inspection.measurements.every(
          (measurement) => measurement.value === ''
        );

        ok = notFilled;
      } else {
        const limits = this.getLimits(inspection);
        const lowerLimit = limits[0];
        const upperLimit = limits[1];

        let measurement = parseFloat(
          inspection.measurements[measurementIndex]?.value
        );
        ok = measurement >= lowerLimit && measurement <= upperLimit;

        if (inspection.type === 'runout' && !inspection.multipleMeasurements) {
          measurement = parseFloat(inspection.measurements[0]?.value);
          ok = measurement >= lowerLimit && measurement <= upperLimit;
        }
      }
      return ok;
    },

    getLimits(inspection) {
      let lowerLimit;
      let upperLimit;
      if (inspection.type === 'runout') {
        upperLimit = Math.abs(inspection.tolerance);
        lowerLimit = -upperLimit;
      } else {
        const floatExpectedValue = parseFloat(inspection.expectedValue);

        const floatNegativeTolerance =
          parseFloat(inspection.negativeTolerance) || 0;
        const floatPositiveTolerance =
          parseFloat(inspection.positiveTolerance) || 0;
        switch (inspection.toleranceType) {
          case 'normal':
            lowerLimit = floatExpectedValue - floatNegativeTolerance;
            upperLimit = floatExpectedValue + floatPositiveTolerance;

            break;
          case 'positive':
            upperLimit = Math.max(
              floatExpectedValue + floatNegativeTolerance,
              floatExpectedValue + floatPositiveTolerance
            );
            lowerLimit = Number.MIN_VALUE;
            break;
          case 'negative':
            lowerLimit = Math.min(
              floatExpectedValue + floatNegativeTolerance,
              floatExpectedValue + floatPositiveTolerance
            );
            upperLimit = Number.MAX_VALUE;
            break;
          default:
            throw new Error(
              `Failed to parse toleranceType for inspection with id ${inspection.id} and type ${inspection.type}: toleranceType ${inspection.toleranceType}`
            );
        }
      }

      let decimalPlaces = this.countDecimalPlaces(inspection.expectedValue);
      if (inspection.type === 'runout') {
        decimalPlaces = this.countDecimalPlaces(inspection.tolerance);
      }

      return [
        parseFloat(lowerLimit).toFixed(decimalPlaces),
        parseFloat(upperLimit).toFixed(decimalPlaces),
      ];
    },

    countDecimalPlaces(value) {
      if (!value) {
        return 0;
      }

      const numString = typeof value === 'number' ? value.toString() : value;

      const parts = numString.split('.');

      if (parts.length === 2) {
        return parts[1].length;
      }
      return 0;
    },

    isInspectorMode() {
      return this.tool === 'inspector';
    },
  },
};
</script>
<style lang="scss" scoped>
.table-content {
  overflow: scroll;
  overflow-x: auto;
  width: 100%;
  height: 100%;
}

.dij-table-measurments {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.table-cell {
  padding: 8px;
  text-align: left;
  box-sizing: border-box;
  min-width: 100px;
}

.table-cell:not(:last-child) {
  border-right: 1px solid #ddd;
}

.table-cell:first-child {
  width: 100px;
  text-align: center;
  overflow: hidden;
}

.odd-row {
  background-color: #ffffff;
}

.even-row {
  background-color: #dddddd48;
}

table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  border: #f5f5f5 3px solid;
  width: max-content;
}

.red-text {
  color: red;
}

.table-header-description {
  font-size: 12px;
  font-weight: normal;
  color: #645757;
}

.table-title {
  width: 120px;
}
</style>
