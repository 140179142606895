<template>
  <div class="wrapper">
    <div class="dij-ra-grid">
      <b-modal
        v-if="isCameraVisible"
        :active="true"
        class="dij-ra-camera-modal"
        full-screen
      >
        <camera @cancel="hideCamera" @ok="processCamera" />
      </b-modal>
      <b-modal
        v-if="isAnnotatorVisible"
        :active="true"
        class="dij-ra-annotator-modal"
        full-screen
      >
        <image-annotator
          :image="imageToBeAnnotated"
          @cancel="hideAnnotator"
          @ok="processAnnotator"
        />
      </b-modal>
      <image-gallery
        class="dij-ra-image-gallery"
        v-if="isGalleryVisible"
        v-model="expandedItem.images"
        @close="hideGallery"
      />
      <template>
        <div class="dij-ra-header">
          <template v-if="isMinorComponents">
            <div class="dij-ra-expand"></div>
            <div class="dij-ra-text">{{ $t('assembly') }}</div>
            <div class="dij-ra-text">{{ $t('item_number') }}</div>
            <div class="dij-ra-text">{{ $t('part_name') }}</div>
            <div class="dij-ra-text">{{ $t('part_number') }}</div>
            <div class="dij-ra-text">{{ $t('quantity') }}</div>
            <div class="dij-ra-text">
              <span class="dij-ra-text">{{ $t('reuse') }}</span>
              <b-icon icon="recycle" size="is-small" />
            </div>
            <div class="dij-ra-text">
              <span class="dij-ra-text">{{ $t('repair') }}</span>
              <b-icon icon="wrench" size="is-small" />
            </div>
            <div class="dij-ra-text">
              <span class="dij-ra-text">{{ $t('replace') }}</span>
              <b-icon icon="delete-forever" size="is-small" />
            </div>

            <div class="dij-ra-text">{{ $t('comments') }}</div>
            <div
              v-if="readOnly"
              class="dij-ra-vertical dij-ra-readonly-text dij-desktop"
            >
              {{ $t('change_log') }}
            </div>
          </template>
          <template v-else>
            <div class="dij-ra-expand"></div>
            <div class="dij-ra-vertical dij-ra-number">{{ $t('level') }}</div>
            <div class="dij-ra-vertical dij-ra-text">{{ $t('assembly') }}</div>
            <div class="dij-ra-vertical dij-ra-number">
              {{ $t('item_number') }}
            </div>
            <div class="dij-ra-vertical dij-ra-text">{{ $t('part_name') }}</div>
            <div class="dij-ra-vertical dij-ra-text">
              {{ $t('part_number') }}
            </div>
            <div class="dij-ra-vertical dij-ra-text">Serial Number</div>
            <div class="dij-ra-vertical dij-ra-number">
              {{ $t('quantity') }}
            </div>
            <div class="dij-ra-vertical dij-ra-readonly-text dij-desktop">
              {{ $t('visual') }}
            </div>
            <div class="dij-ra-vertical dij-ra-readonly-text dij-desktop">
              {{ $t('dim') }}
            </div>
            <div class="dij-ra-vertical dij-ra-readonly-text dij-desktop">
              {{ $t('ndt') }}
            </div>
            <div class="dij-ra-readonly-number">
              <span class="dij-ra-vertical">{{ $t('reuse') }}</span>
              <b-icon icon="recycle" size="is-small" />
            </div>
            <div class="dij-ra-readonly-number">
              <span class="dij-ra-vertical">{{ $t('repair') }}</span>
              <b-icon icon="wrench" size="is-small" />
            </div>
            <div class="dij-ra-readonly-number">
              <span class="dij-ra-vertical">{{ $t('replace') }}</span>
              <b-icon icon="delete-forever" size="is-small" />
            </div>
            <div class="dij-ra-readonly-number">
              <span class="dij-ra-vertical">{{ $t('missing') }}</span>
              <b-icon icon="help-circle-outline" size="is-small" />
            </div>
            <div class="dij-ra-readonly-number">
              <span class="dij-ra-vertical">{{ $t('received') }}</span>
              <b-icon icon="package-variant" size="is-small" />
            </div>
            <div class="dij-ra-vertical dij-ra-readonly-text dij-desktop">
              {{ $t('summary') }}
            </div>
            <div class="dij-ra-vertical dij-ra-readonly-text dij-desktop">
              {{ $t('repair_scope') }}
            </div>
            <div class="dij-ra-vertical dij-ra-readonly-number dij-desktop">
              {{ $t('estimated_labor') }}
            </div>
            <div class="dij-ra-icon">
              <span class="dij-ra-vertical">{{ $t('reviewed') }}</span>
              <b-icon icon="checkbox-marked-circle" size="is-small" />
            </div>
            <div class="dij-ra-icon">
              <span class="dij-ra-vertical">{{ $t('client_reject') }}</span>
              <b-icon icon="cancel" size="is-small" />
            </div>
            <div class="dij-ra-icon dij-ra-scrollbar-aware">
              <span class="dij-ra-vertical">{{
                $t('quality_control_override')
              }}</span>
              <b-icon icon="traffic-light" size="is-small" />
            </div>
            <div
              v-if="readOnly"
              class="dij-ra-vertical dij-ra-readonly-text dij-desktop"
            >
              {{ $t('change_log') }}
            </div>
          </template>
        </div>

        <DynamicScroller
          :items="repairAssessment"
          :min-item-size="47"
          class="virtual-scroller"
        >
          <template #after>
            <div class="dij-ra-empty" v-if="repairAssessment.length === 0">
              <span v-if="readOnly">{{
                $t('repair_assessment_empty_readonly')
              }}</span>
              <span v-else>{{ $t('repair_assessment_search_empty') }}</span>
            </div>
          </template>
          <template v-if="isMinorComponents" v-slot="{ item, index, active }">
            <DynamicScrollerItem
              :item="item"
              :active="active"
              :data-index="index"
            >
              <div
                class="dij-ra-data-row"
                :class="isLockedByAnotherUser(item) ? 'disabled' : ''"
                :title="
                  isLockedByAnotherUser(item) ? getLockedInfoText(item) : ''
                "
                @click="!isRowExpanded(item) && handleExpand(item, $event)"
              >
                <div class="dij-ra-expand">
                  <b-icon
                    :icon="
                      isRowExpanded(item) ? 'file-edit' : 'file-edit-outline'
                    "
                    size="is-medium"
                    @click.native="handleExpand(item, $event)"
                  />
                </div>
                <template v-if="readOnly || !isRowExpanded(item)">
                  <div
                    :class="[
                      { 'dij-ra-changed': isAssemblyChanged(item) },
                      'dij-ra-text',
                    ]"
                  >
                    {{ item.assembly }}
                  </div>
                  <div
                    :class="[
                      { 'dij-ra-changed': isItemNumberChanged(item) },
                      'dij-ra-text',
                    ]"
                  >
                    {{ item.itemNumber }}
                  </div>

                  <div
                    :class="[
                      { 'dij-ra-changed': isPartNameChanged(item) },
                      'dij-ra-text',
                    ]"
                  >
                    {{ item.partName }}
                  </div>
                  <div
                    :class="[
                      { 'dij-ra-changed': isPartNumberChanged(item) },
                      'dij-ra-text',
                    ]"
                  >
                    {{ item.partNumber }}
                  </div>
                  <div
                    :class="[
                      { 'dij-ra-changed': isQuantityChanged(item) },
                      'dij-ra-text',
                    ]"
                  >
                    {{ item.quantity }}
                  </div>
                  <div
                    :class="[
                      { 'dij-ra-changed': isReuseChanged(item) },
                      'dij-ra-text',
                    ]"
                  >
                    {{ item.reuse }}
                  </div>
                  <div
                    :class="[
                      { 'dij-ra-changed': isRepairChanged(item) },
                      'dij-ra-text',
                    ]"
                  >
                    {{ item.repair }}
                  </div>
                  <div
                    :class="[
                      { 'dij-ra-changed': isReplaceChanged(item) },
                      'dij-ra-text',
                    ]"
                  >
                    {{ item.replace }}
                  </div>

                  <div :class="'dij-ra-text'">
                    {{ item.comments }}
                  </div>
                </template>
                <template v-else>
                  <div class="dij-ra-text">
                    <b-input
                      :disabled="isLockedByAnotherUser(item)"
                      :class="[{ 'dij-ra-changed': isAssemblyChanged(item) }]"
                      v-model="item.assembly"
                      @input="handleInputInput(item)"
                    />
                  </div>
                  <div class="dij-ra-text">
                    <b-input
                      :disabled="isLockedByAnotherUser(item)"
                      :class="[{ 'dij-ra-changed': isItemNumberChanged(item) }]"
                      :controls="false"
                      v-model="item.itemNumber"
                      @input="handleInputInput(item)"
                    />
                  </div>
                  <div class="dij-ra-text">
                    <b-input
                      :disabled="isLockedByAnotherUser(item)"
                      :class="[{ 'dij-ra-changed': isPartNameChanged(item) }]"
                      v-model="item.partName"
                      @input="handleInputInput(item)"
                    />
                  </div>
                  <div class="dij-ra-text">
                    <b-input
                      :class="[{ 'dij-ra-changed': isPartNumberChanged(item) }]"
                      :disabled="
                        tool.includes('editor') || isLockedByAnotherUser(item)
                      "
                      v-model="item.partNumber"
                      @input="handleInputInput(item)"
                    />
                  </div>
                  <div class="dij-ra-text">
                    <b-numberinput
                      :disabled="isLockedByAnotherUser(item)"
                      :class="[{ 'dij-ra-changed': isQuantityChanged(item) }]"
                      :controls="false"
                      v-model="item.quantity"
                      @input="handleInputInput(item)"
                    />
                  </div>

                  <div class="dij-ra-text">
                    <b-numberinput
                      :disabled="isLockedByAnotherUser(item)"
                      :class="[{ 'dij-ra-changed': isReuseChanged(item) }]"
                      :controls="false"
                      v-model="item.reuse"
                      @input="handleInputInput(item)"
                    />
                  </div>
                  <div class="dij-ra-text">
                    <b-numberinput
                      :disabled="isLockedByAnotherUser(item)"
                      :class="[{ 'dij-ra-changed': isRepairChanged(item) }]"
                      :controls="false"
                      v-model="item.repair"
                      @input="handleInputInput(item)"
                    />
                  </div>

                  <div class="dij-ra-text">
                    <b-numberinput
                      :disabled="isLockedByAnotherUser(item)"
                      :class="[{ 'dij-ra-changed': isReplaceChanged(item) }]"
                      :controls="false"
                      v-model="item.replace"
                      @input="handleInputInput(item)"
                    />
                  </div>

                  <div class="dij-ra-text">
                    <b-input
                      :disabled="isLockedByAnotherUser(item)"
                      v-model="item.comments"
                      @input="handleInputInput(item)"
                      type="textarea"
                    />
                  </div>
                </template>
                <div class="dij-ra-readonly-text" v-if="readOnly">
                  <b-button
                    icon-right="clipboard-text"
                    @click.stop="onChangeLogButton(item)"
                  />
                </div>
              </div>
            </DynamicScrollerItem>
          </template>
          <template v-else v-slot="{ item, index, active }">
            <DynamicScrollerItem
              :item="item"
              :active="active"
              :data-index="index"
            >
              <div
                class="dij-ra-data-row"
                :class="isLockedByAnotherUser(item) ? 'disabled' : ''"
                :title="
                  isLockedByAnotherUser(item) ? getLockedInfoText(item) : ''
                "
                @click="!isRowExpanded(item) && handleExpand(item, $event)"
              >
                <div class="dij-ra-expand">
                  <b-icon
                    :icon="isRowExpanded(item) ? 'chevron-up' : 'chevron-down'"
                    size="is-medium"
                    @click.native="handleExpand(item, $event)"
                  />
                </div>
                <template v-if="readOnly || !isRowExpanded(item)">
                  <div class="dij-ra-number" v-html="formatLevel(item)"></div>
                  <div
                    :class="[
                      { 'dij-ra-changed': isAssemblyChanged(item) },
                      'dij-ra-text',
                    ]"
                  >
                    {{ item.assembly }}
                  </div>
                  <div
                    :class="[
                      { 'dij-ra-changed': isItemNumberChanged(item) },
                      'dij-ra-number',
                    ]"
                  >
                    {{ item.itemNumber }}
                  </div>
                  <div
                    :class="[
                      { 'dij-ra-changed': isPartNameChanged(item) },
                      'dij-ra-text',
                    ]"
                  >
                    {{ item.partName }}
                  </div>
                  <div
                    :class="[
                      { 'dij-ra-changed': isPartNumberChanged(item) },
                      'dij-ra-text',
                    ]"
                  >
                    {{ item.partNumber }}
                  </div>
                  <div
                    :class="[
                      { 'dij-ra-changed': isSerialNumberChanged(item) },
                      'dij-ra-text',
                    ]"
                  >
                    {{ item.serialNumber }}
                  </div>
                  <div
                    :class="[
                      { 'dij-ra-changed': isQuantityChanged(item) },
                      'dij-ra-number',
                    ]"
                  >
                    {{ item.quantity }}
                  </div>
                </template>
                <template v-else>
                  <div class="dij-ra-number">
                    <b-numberinput
                      :disabled="isLockedByAnotherUser(item)"
                      :controls="false"
                      v-model="item.level"
                      @input="handleInputInput(item)"
                    />
                  </div>
                  <div class="dij-ra-text">
                    <b-input
                      :disabled="isLockedByAnotherUser(item)"
                      :class="[{ 'dij-ra-changed': isAssemblyChanged(item) }]"
                      v-model="item.assembly"
                      @input="handleInputInput(item)"
                    />
                  </div>
                  <div class="dij-ra-number">
                    <b-input
                      :disabled="isLockedByAnotherUser(item)"
                      :class="[{ 'dij-ra-changed': isItemNumberChanged(item) }]"
                      :controls="false"
                      v-model="item.itemNumber"
                      @input="handleInputInput(item)"
                    />
                  </div>
                  <div class="dij-ra-text">
                    <b-input
                      :disabled="isLockedByAnotherUser(item)"
                      :class="[{ 'dij-ra-changed': isPartNameChanged(item) }]"
                      v-model="item.partName"
                      @input="handleInputInput(item)"
                    />
                  </div>
                  <div class="dij-ra-text">
                    <b-input
                      :class="[{ 'dij-ra-changed': isPartNumberChanged(item) }]"
                      :disabled="
                        tool.includes('editor') || isLockedByAnotherUser(item)
                      "
                      v-model="item.partNumber"
                      @input="handleInputInput(item)"
                    />
                  </div>
                  <div class="dij-ra-text">
                    <b-input
                      :class="[
                        { 'dij-ra-changed': isSerialNumberChanged(item) },
                      ]"
                      :disabled="
                        tool.includes('editor') || isLockedByAnotherUser(item)
                      "
                      v-model="item.serialNumber"
                      @input="handleInputInput(item)"
                    />
                  </div>
                  <div class="dij-ra-number">
                    <b-numberinput
                      :disabled="isLockedByAnotherUser(item)"
                      :class="[{ 'dij-ra-changed': isQuantityChanged(item) }]"
                      :controls="false"
                      v-model="item.quantity"
                      @input="handleInputInput(item)"
                    />
                  </div>
                </template>
                <div class="dij-ra-readonly-text dij-desktop">
                  <p>{{ item.visual }}</p>
                </div>
                <div class="dij-ra-readonly-text dij-desktop">
                  <p>{{ item.dim }}</p>
                </div>
                <div class="dij-ra-readonly-text dij-desktop">
                  <p>{{ item.ndt }}</p>
                </div>
                <div class="dij-ra-readonly-number">{{ item.reuse }}</div>
                <div class="dij-ra-readonly-number">{{ item.repair }}</div>
                <div class="dij-ra-readonly-number">{{ item.replace }}</div>
                <div class="dij-ra-readonly-number">{{ item.missing }}</div>
                <div class="dij-ra-readonly-number">{{ item.received }}</div>
                <div class="dij-ra-readonly-text dij-desktop">
                  <p>{{ item.summary }}</p>
                </div>
                <div class="dij-ra-readonly-text dij-desktop">
                  <p>{{ item.repairScope }}</p>
                </div>
                <div class="dij-ra-readonly-number dij-desktop">
                  {{ item.estimatedLabor }}
                </div>
                <div class="dij-ra-icon">
                  <b-icon
                    type="is-success"
                    icon="checkbox-marked-circle"
                    size="is-small"
                    v-if="item.reviewed"
                  />
                </div>
                <div class="dij-ra-icon">
                  <b-icon
                    type="is-danger"
                    icon="cancel"
                    size="is-small"
                    v-if="item.clientReject"
                  />
                </div>
                <div class="dij-ra-icon">
                  <b-icon
                    icon="checkbox-blank-circle"
                    size="is-small"
                    v-if="showQualityControl(item)"
                    :type="getQualityControlType(item)"
                  />
                </div>
                <div class="dij-ra-readonly-text" v-if="readOnly">
                  <b-button
                    icon-right="clipboard-text"
                    @click.stop="onChangeLogButton(item)"
                  />
                </div>
              </div>
              <div class="dij-ra-details-row" v-if="expandedItem === item">
                <div
                  class="dij-ra-details-row-header"
                  v-if="!readOnly && !isLockedByAnotherUser(item)"
                >
                  <div class="dij-ra-file-chooser">
                    <template v-if="isMobileDevice()">
                      <div class="dij-imageUpload-button">
                        <b-icon icon="camera" size="is-medium" />
                        <input
                          class="dij-image-upload"
                          type="file"
                          multiple
                          accept="image/*;capture=camera"
                          @change="handleFileChooserChange"
                        />
                      </div>
                    </template>
                    <template v-else>
                      <b-button
                        style="margin-left: 10px"
                        type="is-primary"
                        outlined
                        icon-left="head-cog-outline"
                        size="is-small"
                        @click="openChatBox"
                        >Ralph-i</b-button
                      >
                      <div class="dij-imageUpload-button">
                        <b-icon icon="folder" size="is-medium" />
                        <input
                          class="dij-image-upload"
                          type="file"
                          multiple
                          accept="image/*;capture=camera"
                          @change="handleFileChooserChange"
                        />
                      </div>
                      <b-icon
                        icon="camera"
                        size="is-medium"
                        @click.native="showCamera()"
                      />
                    </template>
                  </div>
                  <div class="dij-ra-details-gallery">
                    <b-icon
                      icon="image"
                      size="is-medium"
                      :class="
                        expandedItem.images && expandedItem.images.length > 0
                          ? 'dij-ra-gallery-button'
                          : 'dij-ra-gallery-button disabled'
                      "
                      @click.native="showGallery(item)"
                    />
                    <b-tag
                      rounded
                      type="is-info"
                      v-if="
                        expandedItem.images && expandedItem.images.length > 0
                      "
                      @click.native="showGallery(item)"
                      >{{ expandedItem.images.length }}</b-tag
                    >
                  </div>
                </div>
                <div class="dij-ra-findings-block">
                  <div class="dij-ra-block-title">
                    <p class="dij-ra-vertical">
                      {{ $t('findings') }}
                    </p>
                  </div>
                  <div>
                    <p>
                      <b-icon icon="eye" size="is-small" />{{ $t('visual') }}
                    </p>
                    <b-input
                      type="textarea"
                      v-model="item.visual"
                      :disabled="readOnly || isLockedByAnotherUser(item)"
                      @input="handleInputInput(item)"
                    ></b-input>
                  </div>
                  <div>
                    <p>
                      <b-icon icon="ruler" size="is-small" />{{ $t('dim') }}
                    </p>
                    <b-input
                      type="textarea"
                      v-model="item.dim"
                      :disabled="readOnly || isLockedByAnotherUser(item)"
                      @input="handleInputInput(item)"
                    ></b-input>
                  </div>
                  <div>
                    <p>
                      <b-icon icon="magnify-close" size="is-small" />{{
                        $t('ndt')
                      }}
                    </p>
                    <b-input
                      type="textarea"
                      v-model="item.ndt"
                      :disabled="readOnly || isLockedByAnotherUser(item)"
                      @input="handleInputInput(item)"
                    ></b-input>
                  </div>
                </div>
                <div class="dij-ra-recommendations-block">
                  <div class="dij-ra-block-title">
                    <p class="dij-ra-vertical">
                      {{ $t('recommendations') }}
                    </p>
                  </div>
                  <div>
                    <div class="dij-ra-recommendations-quantities-block">
                      <b-field>
                        <template #label>
                          <b-icon icon="recycle" size="is-small" />
                          {{ $t('reuse') }}
                        </template>
                        <b-numberinput
                          controls-position="compact"
                          type="is-light"
                          min="0"
                          v-model="item.reuse"
                          :disabled="readOnly || isLockedByAnotherUser(item)"
                          :controls="!readOnly && !isLockedByAnotherUser(item)"
                          @input="forceUpdateUI(item)"
                        />
                      </b-field>
                      <b-field>
                        <template #label>
                          <b-icon icon="wrench" size="is-small" />
                          {{ $t('repair') }}
                        </template>
                        <b-numberinput
                          controls-position="compact"
                          type="is-light"
                          min="0"
                          v-model="item.repair"
                          :disabled="readOnly || isLockedByAnotherUser(item)"
                          :controls="!readOnly && !isLockedByAnotherUser(item)"
                          @input="forceUpdateUI(item)"
                        />
                      </b-field>
                      <b-field>
                        <template #label>
                          <b-icon icon="delete-forever" size="is-small" />
                          {{ $t('replace') }}
                        </template>
                        <b-numberinput
                          controls-position="compact"
                          type="is-light"
                          min="0"
                          v-model="item.replace"
                          :disabled="readOnly || isLockedByAnotherUser(item)"
                          :controls="!readOnly && !isLockedByAnotherUser(item)"
                          @input="forceUpdateUI(item)"
                        />
                      </b-field>
                      <b-field>
                        <template #label>
                          <b-icon icon="help-circle-outline" size="is-small" />
                          {{ $t('missing') }}
                        </template>
                        <b-numberinput
                          controls-position="compact"
                          type="is-light"
                          min="0"
                          v-model="item.missing"
                          :disabled="readOnly || isLockedByAnotherUser(item)"
                          :controls="!readOnly && !isLockedByAnotherUser(item)"
                          @input="forceUpdateUI(item)"
                        />
                      </b-field>
                    </div>
                    <div class="dij-ra-recommendations-text-block">
                      <div class="dij-ra-recommendations-text-block-first-row">
                        <div>
                          <p>{{ $t('summary') }}</p>
                          <b-input
                            type="textarea"
                            v-model="item.summary"
                            :disabled="readOnly || isLockedByAnotherUser(item)"
                            @input="handleInputInput(item)"
                          ></b-input>
                        </div>
                        <div>
                          <p>{{ $t('repair_scope') }}</p>
                          <b-input
                            type="textarea"
                            v-model="item.repairScope"
                            :disabled="readOnly || isLockedByAnotherUser(item)"
                            @input="handleInputInput(item)"
                          ></b-input>
                        </div>
                      </div>
                      <div class="dij-ra-recommendations-text-block-second-row">
                        <b-field>
                          <template #label>
                            <b-icon icon="clock-outline" size="is-small" />
                            {{ $t('estimated_labor') }}
                          </template>
                          <b-input
                            v-model="item.estimatedLabor"
                            :disabled="readOnly || isLockedByAnotherUser(item)"
                            @input="handleInputInput(item)"
                          />
                        </b-field>
                        <b-button
                          icon-left="checkbox-marked-circle"
                          :title="
                            hasEngineerRole ? '' : $t('not_repair_engineer')
                          "
                          :type="item.reviewed ? 'is-success' : ''"
                          :disabled="
                            readOnly ||
                            !hasEngineerRole ||
                            isLockedByAnotherUser(item)
                          "
                          @click="handleReviewedToggle(item)"
                          >{{ $t('reviewed') }}</b-button
                        >
                        <b-button
                          icon-left="cancel"
                          :type="item.clientReject ? 'is-danger' : ''"
                          :disabled="
                            readOnly ||
                            !item.reviewed ||
                            isLockedByAnotherUser(item)
                          "
                          @click="handleClientRejectToggle(item)"
                          >{{ $t('client_reject') }}</b-button
                        >
                        <b-field
                          class="dij-ra-received"
                          :label="$t('received')"
                        >
                          <template #label>
                            <b-icon icon="package-variant" size="is-small" />
                            {{ $t('received') }}
                          </template>
                          <b-numberinput
                            controls-position="compact"
                            type="is-light"
                            min="0"
                            v-model="item.received"
                            :disabled="readOnly || isLockedByAnotherUser(item)"
                            :controls="
                              !readOnly && !isLockedByAnotherUser(item)
                            "
                            @input="forceUpdateUI(item)"
                          />
                        </b-field>
                        <b-button
                          icon-left="traffic-light"
                          :type="
                            item.qualityControlOverride ? 'is-success' : ''
                          "
                          :disabled="
                            readOnly ||
                            !item.reviewed ||
                            isLockedByAnotherUser(item)
                          "
                          @click="handleQualityControlOverrideToggle(item)"
                          >{{ $t('quality_control_override') }}</b-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DynamicScrollerItem>
          </template>
        </DynamicScroller>
      </template>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';
import { v4 } from 'uuid';
import debounce from 'lodash/debounce';
import Camera from './Camera';
import ImageAnnotator from './ImageAnnotator';
import ImageGallery from './ImageGallery';
import imageHelper from '../../../public/codebase/imageFunctions';
import DIJVueConfigurations from '../../helpers/DIJVueConfigurations';

Vue.component('DynamicScroller', DynamicScroller);

Vue.component('DynamicScrollerItem', DynamicScrollerItem);

const uuidv4 = v4;

export default {
  name: 'repair-assessment-detail',
  props: ['value', 'readOnly', 'tool', 'isMinorComponents'],
  emits: ['input'],
  data() {
    return {
      expandedItem: null,
      imageToBeAnnotated: null,
      filename: null,
      isCameraVisible: false,
      isAnnotatorVisible: false,
      isGalleryVisible: false,
      hasEngineerRole: false,
      updateRowAfterIntervalFunctions: {},
    };
  },
  components: { Camera, ImageAnnotator, ImageGallery },
  computed: {
    repairAssessment: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    job() {
      return this.$store.state.currentJob;
    },
    user() {
      return this.$store.state.currentUser;
    },
    userRoles() {
      return this.$store.state.userRoles;
    },
    waitingForWrite() {
      return this.$store.state.waitingForWrite;
    },
  },
  created() {
    this.value.forEach((item) => {
      if (!item.reuse) {
        item.reuse = 0;
      }
      if (!item.repair) {
        item.repair = 0;
      }
      if (!item.replace) {
        item.replace = 0;
      }
      if (!item.missing) {
        item.missing = 0;
      }
      if (!item.received) {
        item.received = 0;
      }
    });

    this.hasEngineerRole =
      this.user.engineerReview ||
      this.userRoles.some((role) => role.rolename === 'admin');
  },
  mounted() {
    const scrollbarElement = document.querySelector('.vue-recycle-scroller');

    new ResizeObserver(() => {
      const element = document.querySelector('.dij-ra-scrollbar-aware');
      if (!element) return;
      const scrollbarWidth =
        scrollbarElement.offsetWidth - scrollbarElement.clientWidth;
      if (scrollbarWidth > 0) {
        element.style.marginRight = `${scrollbarWidth}px`;
      } else {
        element.style.marginRight = 'unset';
      }
    }).observe(scrollbarElement);
  },
  methods: {
    openChatBox() {
      this.$chatbox.open({
        title: 'Generate Summary',
        message: 'Generating summary for RAT entry...',
      });
    },
    handleInputInput(row) {
      this.$store.dispatch('setWaitingForWrite', true);
      if (!this.updateRowAfterIntervalFunctions[row.id]) {
        this.updateRowAfterIntervalFunctions[row.id] = debounce(
          this.updateRow,
          2000
        );
      }

      this.updateRowAfterIntervalFunctions[row.id](row);
    },

    handleExpand(row, event) {
      if (this.expandedItem === row) {
        if (!this.waitingForWrite && this.isLockedByCurrentUser(row)) {
          this.$store
            .dispatch('unlockRepairAssessmentRow', {
              job: this.job,
              row,
              tool: this.tool,
            })
            .then()
            .catch((error) => {
              this.$buefy.toast.open({
                duration: 5000,
                message: error.response.data.message,
                type: 'is-danger',
              });
              // Mark the selected line as locked
              row.lockedBy = error.response.data.data.lockedBy;
              row.lockedAt = this.getCurrentEpoch();
            });
        }
        this.expandedItem = null;
      } else {
        if (!this.readOnly && !this.isLockedByAnotherUser(row)) {
          this.$store
            .dispatch('lockRepairAssessmentRow', {
              job: this.job,
              row,
              tool: this.tool,
            })
            .then((lockedRow) => {
              // Copy the lock fields
              row.lockedBy = lockedRow.lockedBy;
              row.lockedAt = lockedRow.lockedAt;
              row.changeLog = lockedRow.changeLog;

              let changed = false;
              const keys = Object.keys(lockedRow);
              keys.forEach((key) => {
                if (row[key] && lockedRow[key] && row[key] !== lockedRow[key]) {
                  row[key] = lockedRow[key];
                  changed = true;
                }
              });

              if (changed) {
                const username = row.changeLog[row.changeLog.length - 1].by;
                this.$buefy.toast.open({
                  duration: 5000,
                  message: `${username} changed this row and the new data was loaded`,
                  type: 'is-warning',
                });
              }

              this.enrichRow(row);
            })
            .catch((error) => {
              this.$buefy.toast.open({
                duration: 5000,
                message: error.response.data.message,
                type: 'is-danger',
              });
              // Mark the selected line as locked
              row.lockedBy = error.response.data.data.lockedBy;
              row.lockedAt = this.getCurrentEpoch();
            });
        }

        this.expandedItem = row;
      }

      event.stopPropagation();
    },

    isRowExpanded(row) {
      return this.expandedItem === row;
    },

    addRow({ isMinor = false }) {
      const newRow = this.createBlankRow();

      if (isMinor) newRow.isMinor = isMinor;

      this.$store
        .dispatch('addRepairAssessmentRow', {
          job: this.job,
          rows: newRow,
          tool: this.tool,
        })
        .then(() => {
          this.repairAssessment.push(newRow);
          this.$emit('input', newRow);
        });
    },

    updateRow(row) {
      this.$store
        .dispatch('updateRepairAssessmentRow', {
          job: this.job,
          row,
          tool: this.tool,
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: error.response.data.message,
            type: 'is-danger',
          });
          // Mark the selected line as locked
          row.lockedBy = error.response.data.data.lockedBy;
          row.lockedAt = this.getCurrentEpoch();
        });
    },

    showQualityControl(item) {
      return (
        (item.reviewed &&
          item.reuse + item.repair + item.replace + item.missing !== 0) ||
        item.qualityControlOverride
      );
    },

    getQualityControlType(item) {
      let outcome;

      if (item.qualityControlOverride) {
        outcome = 'is-success';
      } else if (
        !item.quantity ||
        item.quantity === 0 ||
        item.repair > 0 ||
        item.missing > 0 ||
        item.clientReject
      ) {
        outcome = 'is-warning';
      } else if (
        item.reuse === item.quantity ||
        (item.replace > 0 && item.received + item.reuse >= item.quantity)
      ) {
        outcome = 'is-success';
      } else {
        outcome = 'is-danger';
      }

      return outcome;
    },

    forceUpdateUI(row) {
      this.handleInputInput(row);
      this.repairAssessment = [...this.repairAssessment];
    },

    handleClientRejectToggle(row) {
      row.clientReject = !row.clientReject;
      this.forceUpdateUI(row);
    },

    handleQualityControlOverrideToggle(row) {
      row.qualityControlOverride = !row.qualityControlOverride;
      this.forceUpdateUI(row);
    },

    handleReviewedToggle(row) {
      row.reviewed = !row.reviewed;
      this.forceUpdateUI(row);
    },

    showCamera() {
      this.isCameraVisible = true;
    },

    hideCamera() {
      this.isCameraVisible = false;
    },

    processCamera(image) {
      this.hideCamera();
      this.imageToBeAnnotated = image;
      this.showAnnotator();
      this.filename = `${uuidv4()}.jpg`;
    },

    showGallery() {
      if (this.expandedItem.images && this.expandedItem.images.length > 0) {
        this.isGalleryVisible = true;
        document.querySelector('div.dij-ra-header-modal').style.visibility =
          'hidden';
        this.$nextTick(() => {
          document.querySelector('div.dij-ra-image-gallery').style.visibility =
            'visible';
        });
      }
    },

    hideGallery(hasChanged) {
      this.isGalleryVisible = false;
      document.querySelector('div.dij-ra-header-modal').style.visibility =
        'visible';

      if (hasChanged) {
        this.handleInputInput(this.expandedItem);
      }
    },

    handleFileChooserChange(event) {
      // User clicked cancel
      if (event.target.files.length === 0) {
        return;
      }
      const showAnnotator = event.target.files.length === 1;
      if (showAnnotator) {
        const file = event.target.files[0];
        const fileReader = new FileReader();
        fileReader.onload = () => {
          const image = new Image();
          image.onload = () => {
            this.imageToBeAnnotated = image;
            this.showAnnotator();
          };
          image.src = fileReader.result;
        };
        fileReader.readAsDataURL(file);
        this.filename = `${uuidv4()}.jpg`;
      } else {
        const { files } = event.target;
        // this.uploaded = 0;
        // this.total = files.length;
        // this.showUploadProgress = true;
        const promises = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileReader = new FileReader();

          fileReader.onerror = () => {
            fileReader.abort();
          };

          fileReader.onload = () => {
            const image = new Image();
            image.onload = () => {
              const canvas = document.createElement('canvas');
              imageHelper.drawImageFor1920px(canvas, image);
              const fileName = `${uuidv4()}.jpg`;

              const fileToBeSaved = this.dataURLtoFile(
                canvas.toDataURL('image/jpeg', 0.9),
                fileName
              );
              const workOrder = this.job.name;
              const site = this.user.location.substr(0, 3);
              const path = `uploads/sites/${site}/${workOrder}/ra/${this.expandedItem.id}`;
              const promise = this.uploadToS3(fileToBeSaved, path).then(
                (url) => {
                  if (!this.expandedItem.images) {
                    this.expandedItem.images = [];
                  }
                  this.expandedItem.images.push({
                    description: '',
                    name: '',
                    url,
                  });

                  // this.uploaded++;
                  // if (this.uploaded === this.total) {
                  //   setInterval(() => {
                  //     this.showUploadProgress = false;
                  //   }, 2000);
                  // }
                }
              );
              promises.push(promise);
            };

            image.src = fileReader.result;
          };
          fileReader.readAsDataURL(file);
        }
        Promise.all(promises).then(() => {
          this.handleInputInput(this.expandedItem);
        });
      }

      // Clear the file list to allow the user to repeat the upload
      event.target.value = '';
    },
    showAnnotator() {
      this.isAnnotatorVisible = true;
      document.querySelector('div.dij-ra-header-modal').style.visibility =
        'hidden';
      this.$nextTick(() => {
        document.querySelector('div.dij-ra-annotator-modal').style.visibility =
          'visible';
      });
    },
    hideAnnotator() {
      this.isAnnotatorVisible = false;
      this.imageToBeAnnotated = null;
      document.querySelector('div.dij-ra-header-modal').style.visibility =
        'visible';
    },

    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime,
      });
    },

    processAnnotator(image) {
      this.hideAnnotator();

      const fileName = `${this.filename.substr(
        0,
        this.filename.lastIndexOf('.')
      )}.jpg`;
      const canvas = document.createElement('canvas');
      imageHelper.drawImageFor1920px(canvas, image);
      const file = this.dataURLtoFile(
        canvas.toDataURL('image/jpeg', 0.9),
        fileName
      );
      const workOrder = this.job.name;
      const site = this.user.location.substr(0, 3);
      const path = `uploads/sites/${site}/${workOrder}/ra/${this.expandedItem.id}`;
      this.uploadToS3(file, path).then((url) => {
        if (!this.expandedItem.images) {
          this.expandedItem.images = [];
        }
        this.expandedItem.images.push({ description: '', name: '', url });

        this.handleInputInput(this.expandedItem);
      });
    },

    async uploadToS3(file, path) {
      const dijConfigurations = new DIJVueConfigurations();
      const url = await dijConfigurations.uploadFileToS3(file, file.name, path);
      return url;
    },

    isAssemblyChanged(item) {
      return item.originalAssembly
        ? item.originalAssembly !== item.assembly
        : !item.changeLog || item.changeLog[0].tool !== 'publisher';
    },

    isItemNumberChanged(item) {
      return item.originalItemNumber
        ? item.originalItemNumber !== item.itemNumber
        : !item.changeLog || item.changeLog[0].tool !== 'publisher';
    },

    isPartNameChanged(item) {
      return item.originalPartName
        ? item.originalPartName !== item.partName
        : !item.changeLog || item.changeLog[0].tool !== 'publisher';
    },

    isPartNumberChanged(item) {
      return item.originalPartNumber
        ? item.originalPartNumber !== item.partNumber
        : !item.changeLog || item.changeLog[0].tool !== 'publisher';
    },
    isSerialNumberChanged(item) {
      return item.originalSerialNumber
        ? item.originalSerialNumber !== item.serialNumber
        : !item.changeLog || item.changeLog[0].tool !== 'publisher';
    },

    isQuantityChanged(item) {
      return item.originalQuantity
        ? item.originalQuantity !== item.quantity
        : !item.changeLog || item.changeLog[0].tool !== 'publisher';
    },

    isReuseChanged(item) {
      return item.originalReuse
        ? item.originalReuse !== item.reuse
        : !item.changeLog || item.changeLog[0].tool !== 'publisher';
    },

    isRepairChanged(item) {
      return item.originalRepair
        ? item.originalRepair !== item.repair
        : !item.changeLog || item.changeLog[0].tool !== 'publisher';
    },

    isReplaceChanged(item) {
      return item.originalReplace
        ? item.originalReplace !== item.Replace
        : !item.changeLog || item.changeLog[0].tool !== 'publisher';
    },

    formatLevel(item) {
      let formattedLevel = '';
      if (item.level && !isNaN(item.level)) {
        for (let i = 1; i < item.level; i++) {
          formattedLevel += '.';
        }
        formattedLevel += item.level;
      } else {
        formattedLevel = item.level;
      }

      return formattedLevel;
    },

    isLockedByAnotherUser(row) {
      const lockDecayTime = 60 * 60;
      return (
        !!row.lockedBy &&
        row.lockedBy !== this.user._id &&
        row.lockedAt + lockDecayTime > this.getCurrentEpoch()
      );
    },

    isLockedByCurrentUser(row) {
      const lockDecayTime = 60 * 60;
      return (
        !!row.lockedBy &&
        row.lockedBy === this.user._id &&
        row.lockedAt + lockDecayTime > this.getCurrentEpoch()
      );
    },

    getCurrentEpoch() {
      const nowDate = new Date();
      // Remove the timezone/DST offset to get UTC and convert to seconds
      const nowEpoch = Math.round(
        (nowDate.getTime() + nowDate.getTimezoneOffset() * 60 * 1000) / 1000
      );
      return nowEpoch;
    },

    getLockedInfoText(row) {
      return this.$t('repair_assessment_row_locked', {
        user: row.lockedBy,
      });
    },

    isMobileDevice() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },

    /**
     * Create missing row properties
     * This is required due to a bug that causes blur on user input
     * for any field that doesn't have the matching property
     */
    enrichRow(row) {
      const blankRow = this.createBlankRow();

      Object.keys(blankRow).forEach((key) => {
        row[key] = row[key] || blankRow[key];
      });
    },

    createBlankRow() {
      if (this.isMinorComponents) {
        return {
          id: uuidv4(),
          partName: '',
          partNumber: '',
          quantity: 1,
          reuse: 0,
          repair: 0,
          replace: 0,
        };
      }
      return {
        id: uuidv4(),
        level: 1,
        assembly: '',
        itemNumber: '',
        partName: '',
        partNumber: '',
        serialNumber: '',
        quantity: 1,
        reuse: 0,
        repair: 0,
        replace: 0,
        missing: 0,
        clientReject: false,
        received: 0,
        reviewed: false,
        qualityControlOverride: false,
        visual: '',
        dim: '',
        ndt: '',
        summary: '',
        repairScope: '',
        estimatedLabor: '',
      };
    },
    onChangeLogButton(item) {
      const tool = item.changeLog[0].tool.toUpperCase();
      const { by } = item.changeLog[0];
      const date = new Date(item.changeLog[0].at * 1000);

      this.$buefy.dialog.alert({
        title: 'Change Log',
        message: `<div class="ra_change_log"><span>${tool}</span>${by}<span></span><span>${date.getDay()}/${date.getMonth()}/${date.getFullYear()}</span></div>`,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  height: 100%;
  width: 100%;
}
div.dij-ra-empty {
  display: flex;
  justify-content: center;
  margin: 20px;
}
div.vue-recycle-scroller.scroller {
  height: 100%;
}
div.carousel.is-overlay {
  position: fixed !important;
  z-index: 41;
}
div.dij-ra-annotator-modal > ::v-deep div.animation-content.modal-content {
  width: unset;
  height: unset;
  overflow: hidden;
}
div.dij-ra-grid {
  font-size: 14px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .dij-ra-vertical {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    margin-bottom: 5px;
  }

  div.dij-ra-header > div,
  div.dij-ra-data-row > div {
    padding: 2px;

    &.dij-ra-expand {
      min-width: 40px;
      cursor: pointer;
    }

    &.dij-ra-number {
      min-width: 45px;
      flex: 0 0 0;
    }

    &.dij-ra-readonly-text {
      min-width: 100px;
      flex: 1 0 0;
      word-wrap: break-word;
      white-space: break-spaces;

      & > p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.dij-ra-readonly-number,
    &.dij-ra-icon {
      min-width: 30px;
      flex: 0 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &.dij-ra-text {
      min-width: 100px;
      flex: 1 0 0;
    }

    &.disabled {
      background-color: lightgray;
    }
  }

  div.dij-ra-header {
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid lightgray;

    div.dij-ra-actions {
      text-align: center;
    }
  }

  div.dij-ra-data-row {
    display: flex;
    border-bottom: 1px solid lightgray;

    &.disabled {
      background-color: rgb(240, 240, 240);
    }

    div {
      ::v-deep input {
        font-size: 14px;
      }

      &.dij-ra-actions {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.disabled {
        background-color: lightgray;
      }

      &.dij-ra-changed {
        color: red;
      }
    }

    .dij-ra-changed ::v-deep input {
      color: red;
    }
  }

  .dij-ra-details-row {
    background-color: rgb(235, 235, 235);
    padding: 10px;

    .dij-ra-details-row-header {
      display: flex;
      align-items: start;
      justify-content: flex-end;
      gap: 10px;

      span {
        cursor: pointer;

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
          pointer-events: none;
        }
      }

      .dij-ra-details-gallery {
        position: relative;

        span.tag {
          position: absolute;
          top: -10px;
          right: -10px;
        }
      }
    }
  }

  .dij-ra-findings-block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    & > div {
      flex: 1 1 0;
      background-color: white;
      padding: 5px;

      &.dij-ra-block-title {
        font-size: 18px;
        flex: 0 0 30px;
        background-color: unset;
        padding: 0px;
      }

      p {
        text-align: left;

        span {
          margin-right: 5px;
        }
      }
    }
  }

  .dij-ra-recommendations-block {
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      flex: 1 1 0;

      &.dij-ra-block-title {
        font-size: 18px;
        flex: 0 0 30px;
        background-color: unset;
        padding: 0px;
      }
    }
  }

  .dij-ra-recommendations-quantities-block {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 5px;

    // Since all fields except the last have margin-bottom
    // Remove and set it at the parent level
    & > .field {
      flex: 1 1 0;
      margin-bottom: unset;
      background-color: white;
      padding: 4px;

      ::v-deep .label {
        font-size: 14px;
      }

      ::v-deep div.control {
        flex: 1 1 0;
      }
    }
  }

  .dij-ra-recommendations-text-block {
    .dij-ra-recommendations-text-block-first-row {
      display: flex;
      gap: 10px;
      margin-bottom: 5px;

      & > div {
        flex: 1 1 0;
        background-color: white;
        padding: 5px;
      }
    }

    .dij-ra-recommendations-text-block-second-row {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      gap: 30px;
      background-color: white;
      padding: 10px;

      & > .field {
        flex: 1 1 0;
        margin-bottom: unset;

        ::v-deep .label {
          font-size: 14px;
        }

        ::v-deep div.control {
          flex: 1 1 0;
        }
      }
    }
  }

  .dij-ra-action-block {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.dij-desktop {
  display: none;
}
@media (min-width: 1280px) {
  .dij-desktop {
    display: unset;
  }

  div.dij-ra-vertical.dij-ra-text,
  div.dij-ra-vertical.dij-ra-readonly-text {
    writing-mode: unset;
    transform: unset;
  }
}
.dij-ra-file-chooser {
  display: flex;
  gap: 10px;
}
.dij-imageUpload-button {
  position: relative;

  input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 32px;
    height: 32px;
  }

  input[type=file], /* FF, IE7+, chrome (except button) */
  input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.ra_change_log {
  width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span:nth-of-type(1) {
    background-color: #65656533;
    padding: 10px;
    border-radius: 10px;
  }
}
</style>
