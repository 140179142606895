/* eslint-disable lines-between-class-members */

class BubbleDTO {
  _id;

  taskId;

  type;

  label;

  values;

  expectedValue;

  tolerance;

  tolerance1;

  tolerance2;

  unit;

  lastModifiedBy;

  lastModifiedAt;

  measurementName;
  visualName;
  description;
  value;

  condition;

  measurements;

  point;

  startPoint;

  firstPoint;

  secondPoint;

  thirdPoint;

  fourthPoint;

  toleranceType;

  positiveTolerance;

  negativeTolerance;

  measurementCount;

  number;

  inspectorComment;

  images;

  text;

  textSize;

  orientation;

  directionalUnit;

  ratRowID;

  decimalPlaces;

  lengthType;

  multipleMeasurements;
  isoName;
  isoGrade;
  hasDescription;
  descriptionDropdown;
  inTable;

  constructor(data) {
    this._id = data._id;
    this.taskId = data.taskId;
    this.type = data.type;
    this.label = data.label || '';
    this.values = data.values || [];
    this.expectedValue = data.expectedValue || 0;
    this.tolerance =
      data.tolerance === null || data.tolerance === undefined
        ? ''
        : data.tolerance;
    this.tolerance1 = data.tolerance1 || '';
    this.tolerance2 = data.tolerance2 || '';
    this.unit = data.unit;
    this.lastModifiedBy = data.lastModifiedBy || '';
    this.lastModifiedAt = data.lastModifiedAt || '';
    this.measurementName = data.measurementName || '';
    this.visualName = data.visualName || '';
    this.description = data.description || '';
    this.value = data.value || '';
    this.condition = data.condition || '';
    this.measurements = data.measurements || [];
    this.multipleMeasurements = data.multipleMeasurements || false;
    this.point = data.point || {};
    this.startPoint = data.startPoint || {};
    this.firstPoint = data.firstPoint || {};
    this.secondPoint = data.secondPoint || {};
    this.thirdPoint = data.thirdPoint || {};
    this.fourthPoint = data.fourthPoint || {};
    this.toleranceType = data.toleranceType || '';
    this.positiveTolerance = data.positiveTolerance || '';
    this.negativeTolerance = data.negativeTolerance || '';
    this.measurementCount = data.measurementCount || 0;
    this.number = data.number || 0;
    this.inspectorComment = data.inspectorComment || '';
    this.orientation = data.orientation || 'up';
    this.directionalUnit = data.directionalUnit || 'deg';
    this.images = data.images || [];
    this.text = data.text || '';
    this.textSize = data.textSize || 14;
    this.ratRowID = data.ratRowID || undefined;
    this.orientation = data.orientation || 'up';
    this.decimalPlaces = data.decimalPlaces || 4;
    this.lengthType = data.lengthType || '';
    this.isoName = data.isoName;
    this.isoGrade = data.isoGrade;
    this.hasDescription = data.hasDescription;
    this.descriptionDropdown = data.descriptionDropdown;
    this.inTable = data.inTable || false;
  }
}

module.exports = BubbleDTO;
