<template>
  <div class="loading-bar-container">
    <div class="loading-bar">
      <div class="loading-progress" :style="{ width: progress + '%' }"></div>
      <span class="loading-text">Returning answer.. {{ progress }}%</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingBar',
  data() {
    return {
      progress: 0,
    };
  },
  mounted() {
    this.startLoading();
  },
  methods: {
    startLoading() {
      const interval = setInterval(() => {
        if (this.progress < 100) {
          this.progress += 1;
        } else {
          clearInterval(interval);
          setTimeout(() => {
            this.$emit('completed');
          }, 2000);
        }
      }, 50);
    },
  },
};
</script>

<style scoped>
.loading-bar-container {
  width: 100%;
  padding: 10px;
  background-color: #f4f4f8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-bar {
  position: relative;
  width: 80%;
  height: 20px;
  background-color: #e0d7f3;
  border-radius: 10px;
  overflow: hidden;
}

.loading-progress {
  height: 100%;
  background-color: #6a0dad;
  transition: width 0.05s linear;
  border-radius: 10px;
}

.loading-text {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  pointer-events: none;
}
</style>
