var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dij-table-measurments"},[_c('b-collapse',{staticClass:"panel",attrs:{"animation":"slide","aria-id":"contentIdForTable"},on:{"open":() => (_vm.isOpen = true),"close":() => (_vm.isOpen = false)},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('div',{staticClass:"panel-heading table-title",attrs:{"role":"button","aria-controls":"contentIdForTable","aria-expanded":false}},[_c('strong',[_vm._v("Table "),(_vm.isOpen)?_c('b-icon',{attrs:{"icon":"menu-down"}}):_c('b-icon',{attrs:{"icon":"menu-up"}})],1)])]},proxy:true}])},[_c('div',{staticStyle:{"background-color":"white","height":"300px"}},[_c('div',{staticClass:"table-content"},[(_vm.tableBubblesItems.length > 0)?_c('table',[_c('thead',[_c('tr',[_c('th',{staticClass:"table-cell"},[(!_vm.isInspectorMode())?_c('b-button',{attrs:{"type":"is-success is-light","size":"is-small"},on:{"click":_vm.addRow}},[_vm._v("Add row")]):_vm._e()],1),_vm._l((_vm.tableData[0]),function(header,index){return _c('th',{key:`head-${index}`,staticClass:"table-cell",staticStyle:{"text-align":"center"},attrs:{"contenteditable":false}},[_vm._v(" "+_vm._s(header)+" "),(
                    _vm.inspections[index] && _vm.inspections[index].hasDescription
                  )?_c('div',{staticClass:"table-header-description"},[_vm._v(" "+_vm._s(_vm.inspections[index].description)+" ")]):_vm._e()])})],2)]),_vm._l((_vm.tableData.slice(1)),function(row,rowIndex){return _c('tr',{key:`row-${rowIndex}`,class:{
              'odd-row': rowIndex % 2 !== 0,
              'even-row': rowIndex % 2 === 0,
            }},[_c('td',{staticClass:"table-cell",attrs:{"contenteditable":!_vm.isInspectorMode()},on:{"blur":function($event){return _vm.onFirstCellLeave(rowIndex, $event)}}},[_vm._v(" "+_vm._s(_vm.measurementsColumnNames[rowIndex])+" ")]),_vm._l((row),function(cell,colIndex){return _c('td',{key:`cell-${rowIndex}-${colIndex}`,staticClass:"table-cell",class:{
                'red-text': !_vm.areMeasurementsOk(colIndex, rowIndex),
              },attrs:{"data-cell":`${rowIndex}-${colIndex}`,"contenteditable":_vm.isInspectorMode()},domProps:{"textContent":_vm._s(_vm.tableData[rowIndex + 1][colIndex])},on:{"blur":function($event){return _vm.onCellLeave(rowIndex, colIndex, $event)},"focus":function($event){return _vm.onCellFocus(rowIndex, colIndex)}}})})],2)}),_c('tr',[_c('td',{staticClass:"table-cell",attrs:{"contenteditable":false,"colspan":_vm.tableBubblesItems.length + 1}},[_c('b-button',{attrs:{"type":"is-danger","size":"is-small"},on:{"click":_vm.removeRow}},[_vm._v("Remove Row")])],1)])],2):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }