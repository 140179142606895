<template>
  <div class="message-input">
    <b-input
      class="input-full"
      placeholder="Type your message..."
      :expanded="true"
      v-model="newMessage"
      icon-right="arrow-right"
      icon-right-clickable
      @icon-right-click="send"
      @keyup.enter.native="send"
    >
    </b-input>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newMessage: '',
    };
  },
  methods: {
    send() {
      if (!this.newMessage.trim()) return;
      this.$emit('send', this.newMessage.trim());
      this.newMessage = '';
    },
  },
};
</script>

<style scoped>
.message-input {
  display: flex;
  width: 100%;
  gap: 10px;
}

.message-input .input {
  flex: 1;
}

.message-input button {
  flex-shrink: 0;
}

.input-full {
  width: 100%;
}
</style>
