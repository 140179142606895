var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" RUNOUT MEASUREMENTS "),_c('b-table',{attrs:{"data":_vm.runoutData,"striped":true,"narrowed":true},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{attrs:{"field":"label","label":"Label"}},[_vm._v(" "+_vm._s(props.row.label)+" ")]),(_vm.hasDescriptionColumn(_vm.runoutData))?_c('b-table-column',{attrs:{"field":"description","label":"Description"}},[_vm._v(" "+_vm._s(props.row.description ?? '-')+" ")]):_vm._e(),_vm._l((props.row.values),function(item){return (props.row.values.length > 0)?_c('b-table-column',{class:props.row.hasMultipleMeasurements
            ? undefined
            : 'col-value' + _vm.colorValueDimLen(item.diff),attrs:{"field":item.name,"label":item.name}},[_vm._v(" "+_vm._s(item.value)+" "),(
            item.diff &&
            _vm.isInternalReport &&
            !props.row.hasMultipleMeasurements
          )?[_c('div',[_vm._v(_vm._s(item.diff))])]:_vm._e()],2):_vm._e()}),(props.row.hasMultipleMeasurements)?_c('b-table-column',{class:'col-value' + _vm.colorValueDimLen(props.row.tirDiff),attrs:{"field":"tir","label":"TIR"}},[_vm._v(" "+_vm._s(props.row.tir)+" "),(props.row.tirDiff && _vm.isInternalReport)?[_c('div',[_vm._v(_vm._s(props.row.tirDiff))])]:_vm._e()],2):_vm._e(),_c('b-table-column',{attrs:{"field":"expectedValue","label":"Tolerance","visible":_vm.isInternalReport}},[_vm._v(" "+_vm._s(props.row.expectedValue)+" ")]),_c('b-table-column',{attrs:{"field":"unit","label":"Unit"}},[_vm._v(" "+_vm._s(props.row.unit)+" ")]),_c('b-table-column',{attrs:{"field":"lastModifiedBy","label":"Inspector","visible":_vm.isInternalReport}},[_vm._v(" "+_vm._s(props.row.lastModifiedBy)+" ")]),_c('b-table-column',{attrs:{"field":"lastModifiedAt","label":"Date"}},[_vm._v(" "+_vm._s(props.row.lastModifiedAt)+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }