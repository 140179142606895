var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-container",class:{ 'user-container': _vm.isUser }},[(!_vm.isUser)?_c('div',{staticClass:"bot-circle"},[_vm._v("P")]):_vm._e(),_c('div',{class:[
      'chat-bubble',
      _vm.isUser
        ? 'user-message'
        : _vm.canBeCopied
        ? 'copyable-message'
        : 'bot-message',
    ]},[(_vm.canBeCopied)?_c('span',{staticClass:"copyable-content"},[_c('span',[_vm._v(_vm._s(_vm.text))]),_c('b-icon',{staticClass:"copy-icon",attrs:{"title":_vm.canBeCopied ? 'Click to copy' : '',"icon":"content-copy"},nativeOn:{"click":function($event){return _vm.copyToClipboard.apply(null, arguments)}}})],1):_c('span',[_vm._v(" "+_vm._s(_vm.text)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }