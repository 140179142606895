<template>
  <transition name="fade">
    <div v-if="isVisible" class="dialog-container">
      <div
        class="dialog"
        :style="{ top: `${position.y}px`, left: `${position.x}px` }"
      >
        <ChatHeader :title="title" @close="closeChatBox" @drag="startDrag" />
        <ChatBody :messages="messages" />
        <LoadingBar v-if="showLoading" @completed="onCompleted" />
        <ChatFooter @send="addMessage" />
      </div>
    </div>
  </transition>
</template>

<script>
import ChatHeader from './components/ChatHeader';
import ChatBody from './components/ChatBody';
import ChatFooter from './components/ChatFooter';
import LoadingBar from './components/LoadingBar';

export default {
  components: {
    ChatHeader,
    ChatBody,
    ChatFooter,
    LoadingBar,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Chat Box',
    },
    message: {
      type: String,
      default: 'How can I help you?',
    },
  },
  data() {
    return {
      isDragging: false,
      position: { x: window.innerWidth - 520, y: window.innerHeight - 720 },
      offset: { x: 0, y: 0 },
      messages: [],
      showLoading: true,
    };
  },
  watch: {
    isVisible(val) {
      if (val) {
        this.initializeDialogPosition();
      }
    },
    message(newMessage) {
      this.messages = [];
      this.messages.push({ text: newMessage, isUser: false });
      this.showLoading = true;
      setTimeout(() => {
        this.messages.push({
          text: 'This message can be copied into the clipboard!',
          isUser: false,
          canBeCopied: true,
        });
      }, 3000);
    },
  },
  methods: {
    onCompleted() {
      this.showLoading = false;
    },
    closeChatBox() {
      this.$emit('close');
    },
    initializeDialogPosition() {
      // Ensures position is recalculated for visibility
      this.position.x = window.innerWidth - 520;
      this.position.y = window.innerHeight - 720;
    },
    startDrag(event) {
      const dragIcon = event.target.closest('.drag-icon');
      if (dragIcon) {
        this.isDragging = true;
        const dialog = this.$el.querySelector('.dialog');
        this.offset.x = event.clientX - dialog.offsetLeft;
        this.offset.y = event.clientY - dialog.offsetTop;

        document.addEventListener('mousemove', this.onDrag);
        document.addEventListener('mouseup', this.stopDrag);
      }
    },
    onDrag(event) {
      if (!this.isDragging) return;
      this.position.x = event.clientX - this.offset.x;
      this.position.y = event.clientY - this.offset.y;
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.onDrag);
      document.removeEventListener('mouseup', this.stopDrag);
    },
    addMessage(newMessage) {
      this.messages.push({ text: newMessage, isUser: true });
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-from {
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
}

.dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog {
  position: absolute;
  width: 400px;
  height: 500px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
</style>
