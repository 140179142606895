import Vue from 'vue';
import ChatBox from '../components/ChatBox';

const ChatBoxPlugin = {
  // eslint-disable-next-line no-shadow
  install() {
    const ChatBoxConstructor = Vue.extend(ChatBox);
    const chatBoxInstance = new ChatBoxConstructor({
      propsData: {
        isVisible: false,
      },
    });

    chatBoxInstance.$mount();
    document.body.appendChild(chatBoxInstance.$el);

    Vue.prototype.$chatbox = {
      open(options = {}) {
        if (options.title) chatBoxInstance.title = options.title;
        if (options.message) chatBoxInstance.message = options.message;

        chatBoxInstance.isVisible = true;
      },
      close() {
        chatBoxInstance.isVisible = false;
      },
    };

    chatBoxInstance.$on('close', () => {
      chatBoxInstance.isVisible = false;
    });
  },
};

export default ChatBoxPlugin;
