<template>
  <div class="dialog-header">
    <b-icon
      icon="arrow-all"
      class="drag-icon"
      @mousedown.native="onStartDrag"
    />
    <h2 class="dialog-title">{{ title }}</h2>
    <button class="close-btn" @click="onClose">✖</button>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onStartDrag(event) {
      this.$emit('drag', event);
    },
  },
};
</script>

<style scoped>
.dialog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #5b3ea5;
  color: white;
  padding: 10px;
  font-size: 1.2em;
  font-weight: bold;
}

.dialog-title {
  margin: 0 auto;
  text-align: center;
  flex: 1;
}

.drag-icon {
  cursor: grab;
}

.drag-icon:active {
  cursor: grabbing;
}

.close-btn {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
}

.close-btn:hover {
  color: #ff6b6b;
}
</style>
